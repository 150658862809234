// BOOTSTRAP VARIABLE

// Bootstrap Media Queries
$col_xs: 480px;
$col_sm: 768px;
$col_md: 992px;
$col_lg: 1200px;


// Bootstrap Grid
$col_grid: 12;
$col_gutter: 30px;


// Bootstrap Container Size
$cont_tablet: 720px;
$cont_desktop: 940px;
$cont_large_desktop: 1140px;